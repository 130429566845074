<template>
  <div>
    <b-carousel
      pause-text="悬浮中"
      arrow
      indicator-mode="click"
      indicator-position="is-bottom"
      indicator-style="is-dots">
      <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
        <section class="hero is-medium">
          <img :src="carousel.src"/>
          <a :href="carousel.href" target="_blank">
            <div class="carousel-caption">
              <h1 class=" is-size-5">
                <strong style="color: #ffffff">{{ carousel.title }}</strong>
              </h1>
              <p class="">{{ carousel.describe }}</p>
            </div>
          </a>
        </section>
      </b-carousel-item>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "homeHeader",
  data() {
    return {
      testIMG: require("@/assets/indexBanner/index01.webp"),
      carousels: [
        {
          title: 'Mintimate\'s Blog',
          describe: 'Mintimate的博客，只为与你分享。不定期分享干货。',
          src: require("@/assets/indexBanner/index01.webp"),
          href: "https://www.mintimate.cn"
        },
        {
          title: 'Mintimate\'s Bilibili',
          describe: '本站官方Bilibili账号，分享一些使用教程和干货～',
          src: require("@/assets/indexBanner/index02.webp"),
          href: "https://space.bilibili.com/355567627"
        },
        {
          title: 'Mintimate\'s Github',
          describe: 'Github……',
          src: require("@/assets/indexBanner/index03.webp"),
          href: "https://github.com/Mintimate"
        },
      ]
    }
  }
}
</script>
<style>
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 2rem;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
</style>
