<template>
  <div>
    <homeHeader/>
    <section class="box">
      <div class="columns has-text-centered">
        <div class="column">
          近期更新
        </div>
      </div>
      <updateNote/>
    </section>
  </div>
</template>

<script>
import homeHeader from '@/components/Home/homeHeader.vue'
import updateNote from "@/components/Home/updateNote";

export default {
  name: 'Home',
  components: {
    homeHeader,
    updateNote
  },

}
</script>
<style scoped>
hr {
  margin-top: 1rem;
   margin-bottom: 1rem;
   border: 0;
   border-top: 1px solid rgba(0,0,0,.1);
}
</style>
