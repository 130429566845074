<template>
  <div>
    <div class="tile is-ancestor">
      <div class="tile ">
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">大幅修改</p>
            <p class="subtitle">2021.12.11</p>
            <div class="content">
            <ul>
              <li>前后端分离</li>
              <li>重写前端（使用<a href="https://buefy.org/" target="_blank">Vue Buefy）</a></li>
              <li>重写后台代码，重构代码</li>
              <li>优化文件获取，精简地址栏</li>
              <li>暂时不允许注册</li>
              <li>暂时不可使用图床功能</li>
              <li>暂时不可调用夜间模式</li>
            </ul>
            </div>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">新增功能</p>
            <p class="subtitle">2021.03.23</p>
            <div class="content">
              <ul>
                <li>新增图片压缩</li>
                <li>新增文件分类</li>
                <li>调整部分页面操作逻辑和布局</li>
              </ul>
            </div>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">更新代码、架构 </p>
            <p class="subtitle">2020.12.23</p>
            <div class="content">
              <ul>
                <li>修改数据库操作为Mybatis Plus</li>
                <li>调整部分页面操作逻辑和布局</li>
                <li>获取文件算法优化</li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile ">
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">新增功能</p>
            <p class="subtitle">2020.08.30</p>
            <div class="content">
              <ul>
                <li>更新Mac-Homebrew国内安装/卸载源</li>
                <li>大幅度修改底层代码</li>
              </ul>
            </div>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">更新代码、功能</p>
            <p class="subtitle">2020.07.12</p>
            <div class="content">
              <ul>
                <li>添加数据统计功能</li>
                <li>新增文件分类</li>
                <li>调整部分页面操作逻辑和布局</li>
              </ul>
            </div>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">项目初始化</p>
            <p class="subtitle">2020.07.04</p>
            <div class="content">
              <ul>
                <li>开始构建项目</li>
                <li>Springboot+Thymeleaf</li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "updateNote"
}
</script>

<style scoped>

</style>
