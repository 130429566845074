<template>
  <div id="app">
    <div class="myNav">
      <b-navbar
        class="is-light-passive"
        close-on-click
        :mobile-burger=true
        :fixed-top=true>
        <template #brand>
          <b-navbar-item
            tag="router-link"
            :to="{ path: '/' }">
            <img
              :src=webLogo
              alt="IMG2D"
            >
          </b-navbar-item>
        </template>
        <template #start>
          <b-navbar-item
            tag="router-link"
            :to="{path:'/'}">
            首页
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            :to="{ path: '/ImageHost' }"
          >
            图床系统
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            :to="{path:'/DataView'}"
          >
            数据统计
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            :to="{path:'/About'}"
          >
            捐赠/打赏
          </b-navbar-item>
        </template>
        <template #end>
          <b-navbar-item tag="div">
            <template v-if="$store.state.logged">
              <div class="buttons">
                <userCenterButton/>
              </div>
            </template>
            <template v-else>
              <b-button
                tag="router-link"
                icon-left="account"
                :to="{ path: '/login' }"
                class="button is-primary">
                <strong>登录</strong>
              </b-button>
            </template>
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>
    <!--    <my-index/>-->
    <section style="min-height: 75vh">
      <router-view></router-view>
    </section>
    <footer class="footer mt-3">
      <div class="content has-text-centered">
        <p>
          <strong>文件管理</strong> Power by <a href="https://wwww.mintimate.cn">Mintimate</a>.<br/>
          The Online Tool,Help You Deal With Files.
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
import userCenterButton from "@/components/UserNavBar/userCenterButton";
export default {
  name: "mainAPP",
  components:{userCenterButton},
  data() {
    return {
      webLogo: require("./assets/web.svg"),
    }
  },
}
</script>
<style scoped>

section {
  padding: 0.5rem 1.5rem;
}

@media screen and (min-width: 1024px) {
  section {
    padding: 0.5rem 5.5rem;
  }
}

</style>
