import Vue from 'vue'
import Vuex from 'vuex'
import metaModule from './modules/myVueMeta'

import Storage from "@/untils/storage"//引入


Vue.use(Vuex)
// Token-持久化验证
const USER_KEY = 'AuthorizationToken'
const USER_NAME = 'UserName'

let JavaBaseApiURL=""
switch (process.env.NODE_ENV) {
  case 'development':
    JavaBaseApiURL = "http://localhost:8082/"  //开发环境url
    break
  case 'production':
    JavaBaseApiURL = "https://api.host.mintimate.cn"  //生产环境url
    break
}

export default new Vuex.Store({
  state: {
    // 通过 || 设定，如果localStorage没有数据，就返回null空对象
    logged: Storage.get(USER_KEY),
    loggedUserName: Storage.get(USER_NAME),
    // 后端API获取数据
    apiHost:JavaBaseApiURL
  },
  mutations: {
    // Token-修改更新用户信息
    updateUser(state, data) {
      // 1. vuex做更新，使得有响应式
      state.logged = data[0]
      state.loggedUserName=data[1]
      // 2. localStorage做持久更新
      Storage.set(USER_KEY, data[0],1000*60*60*24)
      Storage.set(USER_NAME, data[1],1000*60*60*24)
    },
    // Token-清除用户信息
    clearUser(state) {
      // 1. vuex做清除，使得有响应式
      state.logged = null
      state.loggedUserName=null
      // 2. localStorage做持久清除
      localStorage.removeItem(USER_KEY)
      localStorage.removeItem(USER_NAME)
    }
  },
  actions: {},
  modules: {
    metaModule: metaModule
  },
})
