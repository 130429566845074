import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// buety样式
import buety from 'buefy'
import 'buefy/dist/buefy.css';
// Vue-meta
import VueMeta from "vue-meta";
// Vue WaterFull
import {VueMasonryPlugin} from "vue-masonry";

Vue.config.productionTip = false
Vue.use(buety)
Vue.use(VueMeta)
Vue.use(VueMasonryPlugin)
new Vue({
  router,
  store,
  metaInfo() {
    return {
      title: this.$store.state.metaModule.metaInfo.title,
      meta: [
        {
          name: "keywords",
          content: this.$store.state.metaModule.metaInfo.keywords
        }, {
          name: "description",
          content: this.$store.state.metaModule.metaInfo.description
        },{
          property: 'og:url',
          content: window.location.href
        }
      ]
    }
  },
  render: h => h(App)
}).$mount('#app')

