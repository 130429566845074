<template>
  <b-navbar-dropdown
    right
  >
    <template #label>
        <img :src="avatarIMG" class="is-rounded mr-1"/>
      <span>
      {{$store.state.loggedUserName}}
      </span>
    </template>
    <b-navbar-item
      tag="router-link"
      :to="{ path: '/admin/uploadFile' }">
      文件上传
    </b-navbar-item>
    <b-navbar-item
      tag="router-link"
      :to="{path:'/admin/controlFile'}">
      文件管理
    </b-navbar-item>
    <b-navbar-item
      tag="router-link"
      :to="{path:'/admin/controlImage'}">
      图床管理
    </b-navbar-item>
    <b-navbar-item @click="logOut ">
      退出登录
    </b-navbar-item>
  </b-navbar-dropdown>
</template>

<script>
import axios from "axios";

export default {
  name: "userCenterButton",
  data() {
    return {
      isPublic: true,
      username: "Demo",
      avatarIMG: require("@/assets/avatar/DefautAvatar.jpg"),
    }
  },
  methods: {
    logOut() {
      this.$store.commit("clearUser")
    },
    getUerAvatar() {
      axios.get(this.$store.state.apiHost + "/getAvatar",
        {
          params: {
            Username: this.$store.state.loggedUserName
          }
        })
        .then(res => {
          this.username = res.data.username
          this.avatarIMG = this.$store.state.apiHost + res.data.userAvatarPath
        })
        .catch(() => {
          this.$store.commit("clearUser")
        })
    },
    WaitingDev() {
      this.$buefy.toast.open({
        duration: 5000,
        message: `嘿嘿，被你发现了～这个功能还在制作～`,
        type: 'is-warning',
        pauseOnHover: true
      })
    }
  },
  computed: {
    UserToken() {
      return this.$store.state.logged
    },
  },
  mounted() {
    // this.getUerAvatar()
  },
  watch: {
    // UserToken() {
    //   this.getUerAvatar()
    // }
  }
}
</script>

<style scoped>
@media screen and (max-width: 1024px) {
  .is-rounded {
    display: none;
    padding: 0.5rem 5.5rem;
  }
}
.is-rounded {
  border-radius: 48px;
}
</style>
