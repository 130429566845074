import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import buety from "buefy";
import VueMeta from "vue-meta";
import store from "@/store";

Vue.use(VueRouter, buety)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      metaInfo: {
        title: "Designed By Mintimate",
        keywords: "图床,文件存储,Mintimate,Magisk",
        description: "The Online Tool,Help You Deal With Files.在线工具，为你我提供稳定的在线文件/图片存储和数据统计。"
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
    meta: {
      metaInfo: {
        title: "登录-Designed By Mintimate",
        description: "登录界面（暂时不支持公开注册）"
      }
    }
  },
  {
    path: '/ImageHost',
    name: 'ImageHost',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ImageHost.vue'),
    meta: {
      metaInfo: {
        title: "公共图床-Designed By Mintimate",
        description: "公开类别的图床"
      }
    }
  },
  {
    path: '/imgDetail/:imgID',
    name: 'imgDetail',
    meta: {
      title: "图片详情--Designed By Mintimate"
    },
    component: () => import('@/views/IMGDetail.vue'),
    props: true
  },
  {
    path: '/DataView',
    name: 'DataView',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DataView.vue'),
    meta: {
      metaInfo: {
        title: "数据统计-Designed By Mintimate",
        description: "公开类别文件数据统计"
      }
    }
  },
  {
    path: '/About',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    meta: {
      metaInfo: {
        title: "Designed By Mintimate",
        keywords: "技术支持,打赏,Donate",
        description: "技术支持以及打赏捐赠╮(￣▽￣\"\")╭",
      }
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/AdminViews/admin.vue'),
    children: [
      {
        path: 'uploadFile',
        component: () => import('@/views/AdminViews/adminUploadFile.vue'),
        meta: {
          metaInfo: {
            title: "上传文件-用户中心",
            description: "上传文件（需要提前登录）"
          }
        }
      },
      {
        path: 'controlFile',
        component: () => import('@/views/AdminViews/adminControlFile.vue'),
        meta: {
          metaInfo: {
            title: "管理文件-用户中心",
            description: "管理文件（需要提前登录）"
          }
        }
      },
      {
        path: 'controlImage',
        component: () => import('@/views/AdminViews/adminControlImage.vue'),
        children:[
          {
            path: 'classify',
            component: () => import('@/components/ControlImage/manageImageClassify'),
            meta: {
              metaInfo: {
                title: "管理文件-用户中心",
                description: "管理文件（需要提前登录）"
              }
            }
          },
        ]
      },
    ],
  },
]
const router = new VueRouter({
  routes
})


// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo) {
    store.commit("metaModule/CHANGE_META_INFO", to.meta.metaInfo)
  }
  next()
})


router.beforeEach((to, from, next) => {
  if (to.path.startsWith("/admin")) {
    if (localStorage.getItem("AuthorizationToken") != null) {
      next()
    } else {
      Vue.prototype.$buefy.toast.open({
        type: "is-warning",
        message: "请先登录",
      })
      next("/login")
    }
  } else
    next()
})

function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}

function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'https://host.mintimate.cn')
    .map(route => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}
getRoutesXML()
// console.log(getRoutesXML())


export default router
